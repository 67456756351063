const config = {
  Auth: {
    userPoolId: 'eu-central-1_51J7j16ms',
    userPoolWebClientId: '2d9gke6927o1f59eg6r1i6qlso',
    region: 'eu-central-1',
    mandatorySignIn: true,
    ssoServiceProvider: 'COGNITO',
    oauth: {
      domain: 'makerstreet-test.auth.eu-central-1.amazoncognito.com',
      redirectSignIn: 'https://test.ms-insights.nl/',
      redirectSignOut: 'https://test.ms-insights.nl/',
      scope: ['phone', 'email', 'openid', 'profile'],
      responseType: 'code',
    },
  },
  API: {
    aws_appsync_graphqlEndpoint: 'https://lrrdnc4dnzcdpkhtev4g3gfvdm.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
}

export default config
